<template>
    <BaseInput
        ref="baseInput"
        v-model="date"
        :field="field"
        :error="error"
        :prepend="prepend"
        :required="required"
        autocomplete="off"
        placeholder="MM/DD/YYYY"
        @update:model-value="$emit('update:modelValue', $event)"
        v-bind="$attrs"
    >
        <template #append v-if="!Object.keys($attrs)?.includes('disabled')">
            <CalendarIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </template>
    </BaseInput>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>
<script setup>
import { ref, onMounted, computed } from "vue";
import Pikaday from "pikaday";
import dayjs from "dayjs";
import { CalendarIcon } from "@heroicons/vue/20/solid";
import BaseInput from "@/Shared/Forms/Components/BaseInput.vue";

const model = defineModel();

const props = defineProps({
    field: String,
    error: String,
    prepend: {
        type: String,
        default: null,
    },
    required: Boolean,
    config: {
        type: Object,
        default: () => ({}),
    },
});

const emit = defineEmits(["update:modelValue"]);
const baseInput = ref(null);
const format = props.config.format || "MM/DD/YYYY";

const date = computed(() => {
    return model.value ? dayjs(model.value).format(format) : "";
});

const defaultConfig = {
    format: format,
    yearRange: 5,
    defaultDate: dayjs(date.value).toDate() ?? null,
    setDefaultDate: model ? true : false,
    minDate: false,
    maxDate: false,
    position: "bottom left",
};

onMounted(() => {
    const picker = new Pikaday({
        ...defaultConfig,
        ...props.config,
        field: baseInput.value.inputElement,
        toString(date, format) {
            return dayjs(date).format(format);
        },
        onSelect: () => {
            emit("update:modelValue", picker.toString());
        },
        trigger: baseInput.value.inputElement,
    });
});
</script>
