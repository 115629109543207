<template>
    <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-if="show"
            class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
            @click="cancelHide()"
        >
            <div class="p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0">
                        <CheckCircleIcon
                            v-if="toast.type === 'success'"
                            class="h-6 w-6 text-green-400"
                            aria-hidden="true"
                        />
                        <ExclamationCircleIcon
                            v-else-if="toast.type === 'warning'"
                            class="h-6 w-6 text-yellow-400"
                            aria-hidden="true"
                        />
                        <XCircleIcon
                            v-else-if="toast.type === 'danger'"
                            class="h-6 w-6 text-red-400"
                            aria-hidden="true"
                        />
                        <InformationCircleIcon
                            v-else
                            class="h-6 w-6 text-blue-400"
                            aria-hidden="true"
                        />
                    </div>
                    <div class="ml-3 w-0 flex-1 pt-0.5">
                        <p class="text-sm font-medium text-gray-900">
                            {{ toast.title }}
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                            {{ toast.body }}
                        </p>
                    </div>
                    <div class="ml-4 flex flex-shrink-0">
                        <button
                            type="button"
                            @click="show = false"
                            class="inline-flex rounded-md bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                            :class="{
                                'focus:ring-green-500':
                                    toast.type === 'success',
                                'focus:ring-yellow-500':
                                    toast.type === 'warning',
                                'focus:ring-red-500': toast.type === 'danger',
                                'focus:ring-blue-500': toast.type === 'info',
                            }"
                        >
                            <span class="sr-only">Close</span>
                            <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>

            <!-- Show Timer -->
            <transition
                leave-active-class="transform ease-linear duration-[2.5s] transition-all"
                leave-from-class="w-full"
                leave-to-class="w-px"
            >
                <div
                    v-show="progress"
                    class="h-1"
                    :class="{
                        'bg-green-500': toast.type === 'success',
                        'bg-yellow-500': toast.type === 'warning',
                        'bg-red-500': toast.type === 'danger',
                        'bg-blue-500': toast.type === 'info',
                    }"
                ></div>
            </transition>
        </div>
    </transition>
</template>

<script setup>
import { ref, onMounted } from "vue";
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    XCircleIcon,
} from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/20/solid";

const show = ref(true);
const progress = ref(true);
let timeoutId;

defineProps({
    toast: Object,
});

onMounted(() => {
    progress.value = false;
    timeoutId = setTimeout(() => {
        show.value = false;
    }, 2500);
});

const cancelHide = () => {
    progress.value = true;
    clearTimeout(timeoutId);
};
</script>
