<template>
    <div class="w-full">
        <Checkbox
            :modelValue="modelValue"
            :field="field"
            @update:modelValue="emitChange"
            ><slot
        /></Checkbox>
    </div>
</template>

<script setup>
import Checkbox from "@/Shared/Forms/Checkbox.vue";

defineProps({
    modelValue: Boolean,
    field: { type: String, required: true },
});

const emit = defineEmits(["update:modelValue"]);

const emitChange = (event) => emit("update:modelValue", event);
</script>
