<template>
    <Badge :color="color">
        {{ status }}
    </Badge>
</template>

<script setup>
import Badge from "@/Shared/Badges/Badge.vue";
import { computed } from "vue";

const props = defineProps({
    status: String,
});

const show = computed(() => {
    return props.status !== "Upcoming";
});

const color = computed(() => {
    const status = props.status;
    switch (status) {
        case "in progress":
            return "gray";
        case "approved":
            return "green";
        case "processed":
            return "blue";
        case "returned":
            return "red";
        case "submitted":
            return "purple";
    }
});
</script>
