<template>
    <div class="w-full min-w-[4.5rem] max-w-[10rem]">
        <RevisedSelect
            :options="periods"
            v-model="model"
            :field="field"
            :config="{ label: 'label', key: 'value' }"
            by="value"
            :error-field="false"
            ><slot
        /></RevisedSelect>
    </div>
</template>

<script setup>
import RevisedSelect from "@/Shared/Forms/RevisedSelect.vue";

const model = defineModel();
defineProps({
    periods: Array,
    field: {
        type: String,
        required: true,
    },
});
</script>
