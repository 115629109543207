<template>
    <div class="w-full">
        <Checkbox
            :modelValue="modelValue"
            field="show-completed"
            cy-test="show-completed"
            @update:modelValue="emitChange"
            >Show Completed?</Checkbox
        >
    </div>
</template>

<script setup>
import Checkbox from "@/Shared/Forms/Checkbox.vue";

defineProps({
    modelValue: Boolean,
});

const emit = defineEmits(["update:modelValue"]);

const emitChange = (event) => emit("update:modelValue", event);
</script>
