<template>
    <button
        type="button"
        v-bind="$attrs"
        :class="[
            active ? 'bg-red-100 text-red-900' : 'text-gray-700',
            'group flex w-full items-center px-4 py-2 text-sm',
        ]"
    >
        <TrashIcon
            class="mr-3 h-5 w-5 text-gray-400 group-hover:text-red-500"
            aria-hidden="true"
        />
        Delete
    </button>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { TrashIcon } from "@heroicons/vue/20/solid";

defineProps({
    active: Boolean,
});
</script>
