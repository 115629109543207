<template>
    <Button
        type="submit"
        :size="size"
        :color="color"
        :disabled="processing"
        :class="{ 'cursor-progress': processing }"
        v-bind="$attrs"
    >
        <LoadingSpinner
            v-if="processing"
            class="-ml-1 mr-2 h-5 w-5"
            aria-hidden="true"
        />
        <template v-else>
            <slot name="icon">
                <CheckIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            </slot>
        </template>

        <slot>{{ buttonLabel }}</slot>
    </Button>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { CheckIcon } from "@heroicons/vue/20/solid";
import Button from "@/Shared/Buttons/Button.vue";
import LoadingSpinner from "../LoadingSpinner.vue";

const props = defineProps({
    size: {
        validator(value) {
            return ["sm", "md", "lg", "xl"].includes(value);
        },
        default: "md",
    },

    color: {
        validator(value) {
            return ["blue", "red", "white"].includes(value);
        },
        default: "blue",
    },

    update: {
        type: Boolean,
        default: false,
    },

    processing: {
        type: Boolean,
        default: false,
    },
});

const buttonLabel = props.update ? "Update" : "Save";
</script>
