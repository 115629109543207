<template>
    <div class="w-full min-w-[4.5rem] max-w-[10rem]" data-cy="per-page-filter">
        <RevisedSelect
            :options="options"
            v-model="model"
            field="results-filter"
            :config="{ label: 'label', key: 'value' }"
            by="value"
            :error-field="false"
            >Per Page</RevisedSelect
        >
    </div>
</template>

<script setup>
import RevisedSelect from "@/Shared/Forms/RevisedSelect.vue";

const model = defineModel();

const options = [
    { label: "10", value: 10 },
    { label: "15", value: 15 },
    { label: "25", value: 25 },
    { label: "Show All", value: 9999 },
];
</script>
