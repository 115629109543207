<template>
    <div class="mt-2 bg-blue-100 shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
                Profile Not Verified
            </h3>
            <div class="mt-2 sm:flex sm:items-start sm:justify-between">
                <div class="max-w-4xl text-sm leading-5 text-gray-500">
                    <p class="leading-relaxed" v-html="message"></p>
                </div>
                <div
                    class="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:shrink-0 sm:items-center"
                >
                    <span class="inline-flex rounded-md shadow-sm">
                        <Button :href="link">Verify Profile</Button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Button from "@/Shared/Buttons/Button.vue";

const props = defineProps({
    returning: Boolean,
    link: String,
});

const message = props.returning
    ? "Hi! Welcome back! <br/>It's a new school year, so let's double-check your profile, set your new availability and make sure everything looks right."
    : "Hi! Looks like you're new here. Welcome! <br/>First things first, let's take a look at your profile and make sure everything looks right.";
</script>
