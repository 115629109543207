<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div
        class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
    >
        <!-- Mobile Links -->
        <div class="flex flex-1 justify-between sm:hidden">
            <component
                :is="onFirstPage ? 'span' : 'Link'"
                :href="links.prev"
                class="relative inline-flex items-center rounded-md border bg-white px-4 py-2 text-sm font-medium"
                :class="{
                    'border-gray-300 text-gray-700 hover:bg-blue-50':
                        !onFirstPage,
                    'border-gray-100 text-gray-300': onFirstPage,
                }"
            >
                Previous
            </component>

            <component
                :is="onLastPage ? 'span' : 'Link'"
                :href="links.next"
                class="relative ml-3 inline-flex items-center rounded-md border bg-white px-4 py-2 text-sm font-medium"
                :class="{
                    'border-gray-300 text-gray-700 hover:bg-blue-50':
                        !onLastPage,
                    'border-gray-100 text-gray-300': onLastPage,
                }"
            >
                Next
            </component>
        </div>
        <div
            class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between"
        >
            <div>
                <p class="text-sm text-gray-700">
                    Showing
                    {{ " " }}
                    <span class="font-medium" v-text="meta.from"></span>
                    {{ " " }}
                    to
                    {{ " " }}
                    <span class="font-medium" v-text="meta.to"></span>
                    {{ " " }}
                    of
                    {{ " " }}
                    <span class="font-medium" v-text="meta.total"></span>
                    {{ " " }}
                    results
                </p>
            </div>
            <div>
                <nav
                    class="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                    :class="{
                        '[&>*:nth-child(3)]:hidden [&>*:nth-child(3)]:md:inline-flex [&>*:nth-child(7)]:hidden [&>*:nth-child(7)]:md:inline-flex':
                            linksOnly.length > 5 && !onFirstPage && !onLastPage,
                        '[&>*:nth-child(6)]:hidden [&>*:nth-child(6)]:md:inline-flex [&>*:nth-child(7)]:hidden [&>*:nth-child(7)]:md:inline-flex':
                            linksOnly.length > 5 && onFirstPage,
                        '[&>*:nth-child(3)]:hidden [&>*:nth-child(3)]:md:inline-flex [&>*:nth-child(4)]:hidden [&>*:nth-child(4)]:md:inline-flex':
                            linksOnly.length > 5 && onLastPage,
                    }"
                >
                    <!-- First Button -->
                    <component
                        v-if="linksOnly.length > 5"
                        :is="onFirstPage ? 'span' : 'Link'"
                        :href="links.first"
                        class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium"
                        :class="{
                            'text-gray-500 hover:bg-blue-50': !onFirstPage,
                            'text-gray-300': onFirstPage,
                        }"
                    >
                        <span class="sr-only">First</span>
                        <ChevronDoubleLeftIcon
                            class="h-5 w-5"
                            aria-hidden="true"
                        />
                    </component>

                    <!-- Back Button -->
                    <component
                        :is="onFirstPage ? 'span' : 'Link'"
                        :href="links.prev"
                        class="relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium"
                        :class="{
                            'text-gray-500 hover:bg-blue-50': !onFirstPage,
                            'text-gray-300': onFirstPage,
                            'rounded-l-md': linksOnly.length <= 5,
                        }"
                    >
                        <span class="sr-only">Previous</span>
                        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                    </component>

                    <Link
                        v-for="link of linkButtons"
                        :href="link.url"
                        class="relative inline-flex items-center border px-4 py-2 text-sm font-medium"
                        :class="{
                            'z-10 border-blue-500 bg-blue-50 text-blue-600':
                                link.active,
                            'border-gray-300 bg-white text-gray-500 hover:bg-blue-50':
                                !link.active,
                        }"
                        v-text="link.label"
                    >
                    </Link>

                    <!-- Next Button -->
                    <component
                        :is="onLastPage ? 'span' : 'Link'"
                        :href="links.next"
                        class="relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium"
                        :class="{
                            'text-gray-500 hover:bg-blue-50': !onLastPage,
                            'text-gray-300': onLastPage,
                            'rounded-r-md': linksOnly.length <= 5,
                        }"
                    >
                        <span class="sr-only">Next</span>
                        <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                    </component>

                    <!-- Last Button -->
                    <component
                        v-if="linksOnly.length > 5"
                        :is="onLastPage ? 'span' : 'Link'"
                        :href="links.last"
                        class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium"
                        :class="{
                            'text-gray-500 hover:bg-blue-50': !onLastPage,
                            'text-gray-300': onLastPage,
                        }"
                    >
                        <span class="sr-only">Last</span>
                        <ChevronDoubleRightIcon
                            class="h-5 w-5"
                            aria-hidden="true"
                        />
                    </component>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
} from "@heroicons/vue/20/solid";

const props = defineProps({
    links: Object,
    meta: Object,
});

const onFirstPage = props.links.prev === null;
const onLastPage = props.links.next === null;

const linksOnly = props.meta.links.slice(1, -1);

let getIndicies = () => {
    let current = Number(props.meta.current_page) - 1;

    if (current <= 1) return [0, 5];

    if (current >= linksOnly.length - 3) {
        return [linksOnly.length - 5, linksOnly.length];
    }

    return [current - 2, current + 3];
};

let linkButtons =
    linksOnly.length > 5 ? linksOnly.slice(...getIndicies()) : linksOnly;
</script>
