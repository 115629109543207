<template>
    <div class="px-4 ring-1 ring-gray-300 sm:rounded-lg sm:px-6 lg:px-8">
        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle">
                    <div class="overflow-hidden sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-blue-50">
                                <tr>
                                    <slot name="table-headers" />
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <slot />
                            </tbody>
                        </table>
                        <Paginator
                            v-if="showPagination"
                            :meta="meta"
                            :links="links"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Paginator from "@/Shared/DataFilters/Paginator.vue";

const props = defineProps({
    meta: {
        type: Object,
        default: {},
    },
    links: {
        type: Object,
        default: {},
    },
});

const showPagination =
    !!Object.keys(props.meta).length &&
    !!Object.keys(props.links).length &&
    (props.links.prev || props.links.next);
</script>
