<template>
    <div>
        <Listbox
            as="div"
            v-model="model"
            :disabled="disabled"
            v-bind="$attrs"
            class="grid gap-1"
            :class="{
                'grid-rows-[auto_40px_20px]': errorField,
                'grid-rows-[auto_40px]': !errorField,
            }"
        >
            <div class="flex items-start">
                <ListboxLabel class="block text-sm/6 font-medium text-gray-900">
                    <slot />
                </ListboxLabel>
                <RequiredIcon v-if="required" />
            </div>
            <div class="relative">
                <div class="flex items-center">
                    <div class="relative w-full">
                        <ListboxButton
                            class="grid h-10 w-full cursor-default grid-cols-1 rounded-md py-1.5 pl-3 pr-2 text-left text-gray-900 outline outline-1 -outline-offset-1 focus:outline focus:outline-2 focus:-outline-offset-2 enabled:bg-white disabled:cursor-not-allowed disabled:bg-gray-50 sm:text-sm/6"
                            :class="{
                                ' outline-gray-300 focus:outline-blue-600':
                                    !error,
                                ' outline-red-300 focus:outline-red-600': error,
                            }"
                            :data-cy="`${field}-button`"
                        >
                            <span
                                class="col-start-1 row-start-1 truncate pr-6"
                                :class="{
                                    'text-gray-500': model === null && !error,
                                    'text-red-300': model === null && error,
                                    'text-gray-700': model !== null && !error,
                                    'text-red-900': model !== null && error,
                                }"
                                >{{
                                    model !== null ? model[label] : placeholder
                                }}</span
                            >

                            <FormErrorIcon :error="error" class="pr-8" />

                            <ChevronUpDownIcon
                                class="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                                aria-hidden="true"
                                :class="{ hidden: disabled }"
                            />
                        </ListboxButton>

                        <transition
                            leave-active-class="transition ease-in duration-100"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <ListboxOptions
                                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
                            >
                                <slot name="options">
                                    <DefaultSelectOption
                                        v-if="hasNoneOption"
                                        index="none"
                                        :option="noneOption"
                                    />

                                    <DefaultSelectOption
                                        v-for="(option, index) in options"
                                        :key="option[key]"
                                        :index="index"
                                        :option="option"
                                    />
                                </slot>
                            </ListboxOptions>
                        </transition>
                    </div>
                    <slot name="menu" />
                </div>
            </div>
            <FormErrors v-if="error" :error="error" />
        </Listbox>
    </div>
</template>
<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { computed, provide } from "vue";
import {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOptions,
} from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import FormErrors from "@/Shared/Forms/Components/FormErrors.vue";
import FormErrorIcon from "@/Shared/Forms/Components/FormErrorIcon.vue";
import RequiredIcon from "@/Shared/Forms/Components/RequiredIcon.vue";
import DefaultSelectOption from "@/Shared/Forms/Components/DefaultSelectOption.vue";

const model = defineModel();

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    field: {
        type: String,
        required: true,
    },
    config: {
        type: Object,
        default: {},
    },
    options: Array,
    placeholder: String,
    error: String,
    required: Boolean,
    noneOption: {
        type: Object,
        default: null,
    },
    errorField: {
        type: Boolean,
        default: true,
    },
});

const defaultConfig = {
    key: "id",
    label: "name",
};

const hasNoneOption = computed(() => {
    return props.noneOption !== null;
});

const key = computed(() => props.config?.key ?? defaultConfig.key);
const label = computed(() => props.config?.label ?? defaultConfig.label);

provide("config", {
    key: key.value,
    label: label.value,
    field: props.field,
});
</script>
