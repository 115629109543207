<template>
    <Button :href="href" color="red" @click="openModal()">
        <template v-if="iconPosition === 'left'">
            <TrashIcon class="-ml-1 mr-2 size-5" aria-hidden="true" />
        </template>
        <slot>Delete</slot>
        <template v-if="iconPosition === 'right'">
            <TrashIcon class="-mr-1 ml-3 size-5" aria-hidden="true" />
        </template>
    </Button>
    <DeleteModal ref="deleteModal"></DeleteModal>
</template>

<script setup>
import Button from "@/Shared/Buttons/Button.vue";
import { TrashIcon } from "@heroicons/vue/20/solid";
import DeleteModal from "@/Shared/Modals/DeleteModal.vue";
import { ref } from "vue";

defineProps({
    iconPosition: {
        validator(value) {
            return ["left", "right"].includes(value);
        },
        default: "left",
    },

    href: String,
});

const deleteModal = ref(null);

const openModal = () => {
    deleteModal.value.openModal();
};
</script>
