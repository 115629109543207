<template>
    <div>
        <FormInputLabel :field="field" :required="required">
            <slot />
        </FormInputLabel>

        <div class="relative mt-1">
            <textarea
                :rows="rows"
                :name="field"
                :id="field"
                class="block w-full rounded-md shadow-sm sm:text-sm"
                :class="{
                    'border-gray-300 focus:border-blue-500 focus:ring-blue-500':
                        !error,
                    'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500':
                        error,
                }"
                @input="$emit('update:modelValue', $event.target.value)"
                v-bind="$attrs"
                v-text="modelValue"
            />

            <FormErrorIcon :error="error" position="br" />
        </div>

        <FormErrors v-if="error" :error="error" :field="field" />
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import FormInputLabel from "@/Shared/Forms/Components/FormInputLabel.vue";
import FormErrorIcon from "@/Shared/Forms/Components/FormErrorIcon.vue";
import FormErrors from "@/Shared/Forms/Components/FormErrors.vue";

defineProps({
    field: {
        type: String,
        required: true,
    },
    error: String,
    modelValue: String,
    required: Boolean,
    rows: {
        type: Number,
        default: 4,
    },
});
</script>
