<template>
    <InputWrapper :error-field="errorField">
        <FormInputLabel :field="field" :required="required">
            <slot />
        </FormInputLabel>

        <BaseInput
            v-model="model"
            :field="field"
            :error="error"
            :type="type"
            :required="required"
            :prepend="prepend"
            :append="append"
            :errorIconOnly="errorIconOnly"
            v-bind="$attrs"
        />

        <FormErrors
            v-if="error && !errorIconOnly"
            :error="error"
            :field="field"
        />
    </InputWrapper>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import InputWrapper from "@/Shared/Forms/Components/InputWrapper.vue";
import FormInputLabel from "@/Shared/Forms/Components/FormInputLabel.vue";
import FormErrors from "@/Shared/Forms/Components/FormErrors.vue";
import BaseInput from "@/Shared/Forms/Components/BaseInput.vue";

const model = defineModel();

const props = defineProps({
    field: String,
    error: String,
    type: {
        type: String,
        default: "text",
    },
    prepend: {
        type: String,
        default: null,
    },
    append: {
        type: String,
        default: null,
    },
    errorIconOnly: Boolean,
    required: Boolean,
    errorField: {
        type: Boolean,
        default: true,
    },
});
</script>
