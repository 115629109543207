<template>
    <div class="w-full max-w-[16rem]">
        <FormInput
            v-model="model"
            type="search"
            placeholder="Search..."
            field="search"
            :error-field="false"
        />
    </div>
</template>

<script setup>
import FormInput from "@/Shared/Forms/FormInput.vue";

const model = defineModel();
</script>
