<template>
    <div>
        <Combobox
            as="div"
            :modelValue="modelValue"
            @update:modelValue="emitChange"
            :nullable="nullable"
        >
            <div class="flex items-start">
                <ComboboxLabel class="block text-sm font-medium text-gray-700">
                    <slot />
                </ComboboxLabel>
                <RequiredIcon v-if="required" />
            </div>
            <div class="relative mt-1">
                <ComboboxInput
                    class="relative w-full rounded-md border bg-white py-2 pl-3 shadow-sm focus:outline-none focus:ring-1 sm:text-sm"
                    :class="{
                        'border-gray-300 pr-10 focus:border-blue-500 focus:ring-blue-500':
                            !error,
                        'border-red-300 pr-20 focus:border-red-500 focus:ring-red-500':
                            error,
                        'text-gray-500': modelValue === null && !error,
                        'text-red-300': modelValue === null && error,
                        'text-gray-700': modelValue !== null && !error,
                        'text-red-900': modelValue !== null && error,
                    }"
                    @change="query = $event.target.value"
                    :display-value="(item) => item?.label"
                    v-bind="$attrs"
                />
                <FormErrorIcon :error="error" class="pr-8" />

                <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                >
                    <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </ComboboxButton>

                <ComboboxOptions
                    v-if="filteredItems.length > 0"
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <ComboboxOption
                        v-for="item in filteredItems"
                        :key="item.value"
                        :value="item"
                        as="template"
                        v-slot="{ active, selected }"
                    >
                        <li
                            :class="[
                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                active
                                    ? 'bg-blue-600 text-white'
                                    : 'text-gray-900',
                            ]"
                        >
                            <span
                                :class="[
                                    'block truncate',
                                    selected && 'font-semibold',
                                ]"
                            >
                                {{ item.label }}
                            </span>

                            <span
                                v-if="selected"
                                :class="[
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                    active ? 'text-white' : 'text-blue-600',
                                ]"
                            >
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                        </li>
                    </ComboboxOption>
                </ComboboxOptions>
            </div>
        </Combobox>

        <FormErrors v-if="error" :error="error" />
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { computed, ref } from "vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from "@headlessui/vue";
import FormErrorIcon from "@/Shared/Forms/Components/FormErrorIcon.vue";
import FormErrors from "@/Shared/Forms/Components/FormErrors.vue";
import RequiredIcon from "@/Shared/Forms/Components/RequiredIcon.vue";

const props = defineProps({
    options: Array,
    modelValue: Object,
    error: String,
    required: Boolean,
    nullable: Boolean,
});

const emit = defineEmits(["update:modelValue"]);

const query = ref("");

const emitChange = (event) => emit("update:modelValue", event);

const filteredItems = computed(() =>
    query.value === ""
        ? props.options
        : props.options.filter((item) => {
              return item.label
                  .toLowerCase()
                  .includes(query.value.toLowerCase());
          })
);
</script>
