<template>
    <InputWrapper :error-field="errorField">
        <FormInputLabel :field="field" :required="required">
            <slot />
        </FormInputLabel>

        <BaseInput
            x-data
            x-mask:dynamic="$money($input)"
            v-model="model"
            :field="field"
            :error="error"
            type="tel"
            :required="required"
            :prepend="prepend"
            :append="append"
            v-bind="$attrs"
        >
            <slot />
        </BaseInput>

        <FormErrors v-if="error" :error="error" :field="field" />
    </InputWrapper>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>
<script setup>
import FormInputLabel from "@/Shared/Forms/Components/FormInputLabel.vue";
import FormErrors from "@/Shared/Forms/Components/FormErrors.vue";
import BaseInput from "@/Shared/Forms/Components/BaseInput.vue";
import InputWrapper from "@/Shared/Forms/Components/InputWrapper.vue";

const model = defineModel();

const props = defineProps({
    field: String,
    error: String,
    prepend: {
        type: String,
        default: null,
    },
    append: {
        type: String,
        default: null,
    },
    required: Boolean,
    errorField: {
        type: Boolean,
        default: true,
    },
});
</script>
