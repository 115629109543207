<template>
    <component
        :is="isButton ? 'button' : 'Link'"
        :type="isButton ? 'button' : undefined"
        class="inline-flex justify-center rounded-md border font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-75"
        :class="{
            'leading-4': size === 'sm',
            'py-2': ['sm', 'md', 'lg'].includes(size),
            'py-3 ': size === 'xl',
            'text-sm': ['sm', 'md'].includes(size),
            'text-base': ['lg', 'xl'].includes(size),
            'px-2': iconOnly && ['sm', 'md', 'lg'].includes(size),
            'px-3': iconOnly && size === 'xl',
            'px-3': !iconOnly && size === 'sm',
            'px-4': !iconOnly && ['md', 'lg'].includes(size),
            'px-6': !iconOnly && size === 'xl',
            'border-transparent bg-blue-600 text-white focus:ring-blue-500 enabled:hover:bg-blue-700':
                color === 'blue',
            'hover:bg-blue-700': !isButton && color === 'blue',
            'border-transparent bg-red-600 text-white focus:ring-red-500 enabled:hover:bg-red-700':
                color === 'red',
            'hover:bg-red-700': !isButton && color === 'red',
            'border-transparent bg-green-600 text-white focus:ring-green-500 enabled:hover:bg-green-700':
                color === 'green',
            'hover:bg-green-700': !isButton && color === 'green',
            'border-gray-300 bg-white text-gray-700 focus:ring-blue-500 enabled:hover:bg-gray-50':
                color === 'white',
            'hover:bg-gray-50': !isButton && color === 'white',
            'border-red-300 bg-white text-red-600 focus:ring-red-500 enabled:hover:bg-red-50':
                color === 'white-red',
            'hover:bg-red-50': !isButton && color === 'white-red',
            'border-blue-300 bg-white text-blue-600 focus:ring-blue-500 enabled:hover:bg-blue-50':
                color === 'white-blue',
            'hover:bg-blue-50': !isButton && color === 'white-blue',
        }"
        :href="isButton ? undefined : href"
    >
        <slot />
    </component>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    size: {
        validator(value) {
            return ["sm", "md", "lg", "xl"].includes(value);
        },
        default: "md",
    },

    color: {
        validator(value) {
            return [
                "blue",
                "green",
                "red",
                "white",
                "white-red",
                "white-blue",
            ].includes(value);
        },
        default: "blue",
    },

    href: {
        type: String,
        default: "",
    },

    iconOnly: Boolean,
});

const isButton = computed(() => {
    return props.href === "";
});
</script>
