<template>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img
            class="mx-auto h-32 w-auto sm:h-48"
            src="/svg/st_logo_vertical.svg"
            alt="Success Together"
        />
        <h2
            class="mt-6 text-center text-3xl font-extrabold leading-9 text-gray-900"
        >
            <slot name="heading" />
        </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <slot />
        </div>
    </div>

    <div
        aria-live="assertive"
        class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
        <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
            <Toast v-for="toast in toasts" :toast="toast" />
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import Toast from "@/Shared/Toast.vue";
import { router } from "@inertiajs/vue3";

const toasts = reactive([]);
const sessionToast = ref(router.page.props.toast);

onMounted(() => {
    window.addEventListener("flash", (data) => pushToast(data));

    if (sessionToast.value) {
        pushToast({ detail: sessionToast.value });
    }
});

const pushToast = (toast) => {
    toasts.push(toast.detail);
};
</script>
