<template>
    <div
        class="text-sm/6"
        :class="{ 'ml-3': boxSide === 'left', 'mr-3': boxSide === 'right' }"
    >
        <label :for="field" class="block font-medium text-gray-900">
            <slot />
        </label>
        <p
            v-if="props.description?.trim() !== ''"
            :id="descriptionId"
            class="text-gray-500"
            v-text="description"
        ></p>
    </div>
</template>

<script setup>
const props = defineProps({
    disabled: Boolean,
    descriptionId: String,
    description: String,
    field: {
        type: String,
        required: true,
    },
    boxSide: {
        type: String,
        validator: (value) => ["left", "right"].includes(value),
        default: "left",
    },
});
</script>
