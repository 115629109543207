<template>
    <SwitchGroup as="div" class="flex items-center">
        <Switch
            :model-value="model"
            @update:modelValue="emitChange"
            :disabled="disabled"
            class="relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
            :class="{
                'bg-gray-200': !model,
                'bg-blue-600': model && color === 'blue',
                'focus:ring-blue-600': color === 'blue',
                'bg-red-600': model && color === 'red',
                'focus:ring-red-600': color === 'red',
            }"
        >
            <span
                aria-hidden="true"
                :class="[
                    model ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                ]"
            />
        </Switch>
        <span class="ml-3 flex flex-grow flex-col">
            <SwitchLabel
                as="span"
                class="text-sm/6 font-medium"
                :class="{
                    'text-gray-500': disabled,
                    'text-gray-900': !disabled,
                }"
                passive
                ><slot
            /></SwitchLabel>

            <SwitchDescription
                as="span"
                v-if="description"
                class="text-sm text-gray-500"
                >{{ description }}</SwitchDescription
            >
        </span>
    </SwitchGroup>
</template>

<script setup>
import {
    Switch,
    SwitchGroup,
    SwitchDescription,
    SwitchLabel,
} from "@headlessui/vue";

const model = defineModel();

defineProps({
    color: {
        validator(value) {
            return ["blue", "red"].includes(value);
        },
        default: "blue",
    },
    description: String,
    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["update:modelValue"]);

const emitChange = (event) => emit("update:modelValue", event);
</script>
