<template>
    <div>
        <div class="px-4 md:px-0">
            <div class="availability-grid grid gap-1">
                <div class="col-auto"></div>
                <div
                    v-for="day in days"
                    class="py-1 text-center text-sm sm:py-2 sm:text-base"
                >
                    {{ day.tiny_name }}
                </div>

                <template v-for="(timeslot, label) in timeslots">
                    <div
                        class="col-auto mr-4 min-w-max items-center justify-end text-xs sm:h-6 md:text-sm xl:mr-2"
                        v-text="label"
                    ></div>
                    <template v-for="cell in timeslot">
                        <AvailabilityCell
                            v-if="cell"
                            :timeslot="cell.data"
                            :available="availableOn(cell)"
                        />
                        <BaseAvailabilityCell v-else />
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import AvailabilityCell from "@/Shared/Availability/AvailabilityCell.vue";
import BaseAvailabilityCell from "@/Shared/Availability/BaseAvailabilityCell.vue";

const props = defineProps({
    timeslots: Object,
    availability: Object,
});

const ranges = Object.keys(props.timeslots);
const availableOn = (timeslot) => {
    return props.availability.map((a) => a.id).includes(timeslot.data.id);
};

const days = [
    { tiny_name: "S", index: 0 },
    { tiny_name: "M", index: 1 },
    { tiny_name: "T", index: 2 },
    { tiny_name: "W", index: 3 },
    { tiny_name: "T", index: 4 },
    { tiny_name: "F", index: 5 },
    { tiny_name: "S", index: 6 },
];
</script>
