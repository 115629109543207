<template>
    <Badge v-if="show" :color="color">
        {{ status }}
    </Badge>
</template>

<script setup>
import Badge from "@/Shared/Badges/Badge.vue";
import { computed } from "vue";

const props = defineProps({
    status: String,
    showUpcoming: Boolean,
});

const show = computed(() => {
    return props.showUpcoming || props.status !== "Upcoming";
});

const color = computed(() => {
    const status = props.status;
    switch (status) {
        case "N/A":
            return "gray";
        case "Upcoming":
            return "blue";
        case "Ongoing":
            return "yellow";
        case "Postponed":
            return "purple";
        case "Complete":
            return "red";
    }
});
</script>
