<template>
    <div>
        <Listbox
            as="div"
            :modelValue="modelValue"
            @update:modelValue="emitChange"
            :disabled="disabled"
            v-bind="$attrs"
        >
            <div class="flex items-start">
                <ListboxLabel class="block text-sm font-medium text-gray-700">
                    <slot />
                </ListboxLabel>
                <RequiredIcon v-if="required" />
            </div>
            <div class="mt-1">
                <div class="flex items-center">
                    <div class="relative w-full">
                        <ListboxButton
                            class="relative w-full cursor-default rounded-md border py-2 pl-3 text-left shadow-sm focus:outline-none focus:ring-1 enabled:bg-white disabled:cursor-not-allowed disabled:bg-gray-50 sm:text-sm"
                            :class="{
                                'border-gray-300 pr-10 focus:border-blue-500 focus:ring-blue-500':
                                    !error,
                                'border-red-300 pr-20 focus:border-red-500 focus:ring-red-500':
                                    error,
                            }"
                        >
                            <span
                                class="block truncate"
                                :class="{
                                    'text-gray-500':
                                        modelValue === null && !error,
                                    'text-red-300':
                                        modelValue === null && error,
                                    'text-gray-700':
                                        modelValue !== null && !error,
                                    'text-red-900':
                                        modelValue !== null && error,
                                }"
                                >{{
                                    modelValue !== null
                                        ? modelValue.label
                                        : placeholder
                                }}</span
                            >
                            <FormErrorIcon :error="error" class="pr-8" />
                            <span
                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                            >
                                <ChevronUpDownIcon
                                    class="h-5 w-5 text-gray-400"
                                    :class="{ hidden: disabled }"
                                    aria-hidden="true"
                                />
                            </span>
                        </ListboxButton>

                        <transition
                            leave-active-class="transition ease-in duration-100"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <ListboxOptions
                                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            >
                                <ListboxOption
                                    v-if="hasNoneOption"
                                    :value="{
                                        label: noneOption.label,
                                        value: noneOption.value,
                                    }"
                                    v-slot="{ active, selected }"
                                >
                                    <li
                                        :class="[
                                            active
                                                ? 'bg-blue-600 text-white'
                                                : 'text-gray-900',
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                        ]"
                                    >
                                        <span
                                            :class="[
                                                selected
                                                    ? 'font-semibold'
                                                    : 'font-normal',
                                                'block truncate',
                                            ]"
                                        >
                                            {{ noneOption.label }}
                                        </span>

                                        <span
                                            v-if="selected"
                                            :class="[
                                                active
                                                    ? 'text-white'
                                                    : 'text-blue-600',
                                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                            ]"
                                        >
                                            <CheckIcon
                                                class="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </li>
                                </ListboxOption>

                                <ListboxOption
                                    as="template"
                                    v-for="option in options"
                                    :key="option.value"
                                    :value="option"
                                    v-slot="{ active, selected }"
                                >
                                    <li
                                        :class="[
                                            active
                                                ? 'bg-blue-600 text-white'
                                                : 'text-gray-900',
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                        ]"
                                    >
                                        <span
                                            :class="[
                                                selected
                                                    ? 'font-semibold'
                                                    : 'font-normal',
                                                'block truncate',
                                            ]"
                                        >
                                            {{ option.label }}
                                        </span>

                                        <span
                                            v-if="selected"
                                            :class="[
                                                active
                                                    ? 'text-white'
                                                    : 'text-blue-600',
                                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                            ]"
                                        >
                                            <CheckIcon
                                                class="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </li>
                                </ListboxOption>
                            </ListboxOptions>
                        </transition>
                    </div>
                    <slot name="menu" />
                </div>
            </div>
        </Listbox>

        <FormErrors v-if="error" :error="error" />
    </div>
</template>
<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { computed } from "vue";
import {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import FormErrors from "@/Shared/Forms/Components/FormErrors.vue";
import FormErrorIcon from "@/Shared/Forms/Components/FormErrorIcon.vue";
import RequiredIcon from "@/Shared/Forms/Components/RequiredIcon.vue";

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    options: Array,
    modelValue: Object,
    placeholder: String,
    error: String,
    required: Boolean,
    noneOption: Object,
});

const emit = defineEmits(["update:modelValue"]);

const emitChange = (event) => emit("update:modelValue", event);

const hasNoneOption = computed(() => {
    return typeof props.noneOption !== "undefined";
});
</script>
