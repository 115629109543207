<template>
    <section :aria-labelledby="headingId">
        <div class="shadow sm:rounded-md">
            <div class="bg-white py-6 px-4 sm:rounded-t-md sm:p-6">
                <div>
                    <h2
                        :id="headingId"
                        class="text-lg font-medium leading-6 text-gray-900"
                    >
                        {{ title }}
                    </h2>
                    <p class="mt-1 text-sm text-gray-500">
                        <slot name="subheading" />
                    </p>
                </div>

                <div class="mt-6 grid grid-cols-4 gap-6">
                    <slot />
                </div>
            </div>

            <slot name="buttons" />
        </div>
    </section>
</template>

<script setup>
import { computed } from "vue";
import { kebabCase } from "lodash";

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
});

const headingId = computed(() => {
    return kebabCase(props.title) + "-heading";
});
</script>
