<template>
    <div class="relative">
        <div
            v-if="prependsItem"
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        >
            <span
                class="text-gray-500 sm:text-sm"
                v-text="prepend"
                v-if="!slots.prepend"
            ></span>
            <slot name="prepend" />
        </div>
        <input
            v-model="model"
            :name="field"
            :id="field"
            :data-cy="`${field}-input`"
            :type="type"
            class="block h-10 w-full rounded-md py-1.5 text-base outline outline-1 -outline-offset-1 focus:outline focus:outline-2 focus:-outline-offset-2 focus:ring-0 enabled:bg-white disabled:bg-gray-50 sm:text-sm/6"
            :class="{
                'col-start-1 row-start-1 pr-10 text-red-900 outline-red-300 placeholder:text-red-300 focus:outline-red-600 focus:ring-red-600 sm:pr-9':
                    error,
                ' text-gray-900 outline-gray-300 placeholder:text-gray-400 focus:outline-blue-600':
                    !error,
                'pl-7': prependsItem,
                'pl-3': !prependsItem,
                'pr-12': appendsItem && !error,
                'pr-10': !appendsItem && error,
                'pr-22': appendsItem && error,
            }"
            ref="inputElement"
            v-bind="$attrs"
        />
        <div
            v-if="appendsItem && !error"
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
        >
            <span
                class="text-gray-500 sm:text-sm"
                v-text="append"
                v-if="!slots.append"
            ></span>
            <slot name="append" />
        </div>
        <FormErrorIcon :error="error" />
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import FormErrorIcon from "@/Shared/Forms/Components/FormErrorIcon.vue";
import { computed, useSlots, ref } from "vue";

const model = defineModel();

const inputElement = ref(null);

defineExpose({
    inputElement,
});

const slots = useSlots();

const props = defineProps({
    field: {
        type: String,
        required: true,
    },
    error: String,
    type: {
        type: String,
        default: "text",
    },
    prepend: {
        type: String,
        default: null,
    },
    append: {
        type: String,
        default: null,
    },
    errorIconOnly: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
});

const prependsItem = computed(() => {
    return props.prepend !== null || slots.prepend;
});

const appendsItem = computed(() => {
    return props.append !== null || slots.append;
});
</script>
