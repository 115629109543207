<template>
    <div class="group inline-flex">
        <template v-if="showLink">
            <Link
                :href="showLink"
                @click.right.ctrl.prevent="visitEdit"
                class="st-link"
            >
                <slot />
            </Link>
        </template>
        <template v-else>
            <slot />
        </template>
    </div>
</template>

<script setup>
import { router } from "@inertiajs/vue3";

const props = defineProps({
    editLink: String,
    showLink: String,
});

const visitEdit = () => {
    if (!props.editLink) {
        router.visit(props.showLink);
        return;
    }

    router.visit(props.editLink);
};
</script>
