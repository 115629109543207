<template>
    <div>
        <div
            class="min-h-full rounded"
            :class="background"
            v-bind="$attrs"
        ></div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
defineProps({
    background: {
        type: String,
        default: "bg-gray-300",
    },
});
</script>
