<template>
    <div>
        <!-- Page Header -->
        <slot name="header" />

        <!-- Main Content section -->
        <main>
            <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                <slot />
                <!-- /End replace -->
            </div>
        </main>

        <div
            aria-live="assertive"
            class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
        >
            <div
                class="flex w-full flex-col items-center space-y-4 sm:items-end"
            >
                <Toast v-for="toast in toasts" :toast="toast" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import Toast from "@/Shared/Toast.vue";
import { router } from "@inertiajs/vue3";

const toasts = reactive([]);
const sessionToast = ref(router.page.props.toast);

onMounted(() => {
    window.addEventListener("flash", (data) => pushToast(data));

    if (sessionToast.value) {
        pushToast({ detail: sessionToast.value });
    }
});

const pushToast = (toast) => {
    toasts.push(toast.detail);
};
</script>
