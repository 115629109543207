<template>
    <div class="relative flex items-start">
        <div v-if="boxSide === 'left'" class="flex h-5 items-center">
            <BaseCheckbox
                :id="field"
                :name="field"
                :checked="isChecked"
                :disabled="disabled"
                :aria-describedBy="showDescription ? describedBy : undefined"
                @change="handleChange"
            />
        </div>

        <CheckboxLabel
            :box-side="boxSide"
            :field="field"
            :description="description"
            :description-id="showDescription ? describedBy : undefined"
        >
            <slot />
        </CheckboxLabel>

        <div v-if="boxSide === 'right'" class="flex h-5 items-center">
            <BaseCheckbox
                :id="field"
                :name="field"
                :checked="isChecked"
                :disabled="disabled"
                :aria-describedBy="showDescription ? describedBy : undefined"
                @change="handleChange"
            />
        </div>
    </div>
</template>

<script setup>
import { computed, toRaw } from "vue";
import BaseCheckbox from "@/Shared/Forms/Components/BaseCheckbox.vue";
import CheckboxLabel from "@/Shared/Forms/Components/CheckboxLabel.vue";

const props = defineProps({
    modelValue: {
        type: Array,
        default: () => [],
    },
    field: {
        type: String,
        required: true,
    },
    value: {
        type: [String, Number, Boolean, Object],
        default: true,
    },
    boxSide: {
        type: String,
        default: "left",
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    description: String,
});

const emit = defineEmits(["update:modelValue"]);

const isEqual = (a, b) => {
    const rawA = toRaw(a);
    const rawB = toRaw(b);

    return rawA?.value !== undefined && rawB?.value !== undefined
        ? rawA.value === rawB.value
        : rawA === rawB;
};

const isChecked = computed(() =>
    props.modelValue.some((item) => isEqual(item, props.value))
);

const handleChange = (event) => {
    const checked = event.target.checked;
    const newValue = [...props.modelValue];

    if (checked && !newValue.some((item) => isEqual(item, props.value))) {
        newValue.push(props.value);
    } else if (!checked) {
        const index = newValue.findIndex((item) => isEqual(item, props.value));
        if (index > -1) {
            newValue.splice(index, 1);
        }
    }

    emit("update:modelValue", newValue);
};

const showDescription = computed(() => !!props.description);
const describedBy = computed(() => `${props.field}-description`);
</script>
