<template>
    <div class="relative flex items-start">
        <div v-if="boxSide === 'left'" class="flex h-5 items-center">
            <BaseCheckbox
                :id="field"
                :name="field"
                :checked="modelValue"
                :disabled="disabled"
                :aria-describedBy="showDescription ? describedBy : undefined"
                @change="handleChange"
            />
        </div>

        <CheckboxLabel
            :box-side="boxSide"
            :field="field"
            :description="description"
            :description-id="showDescription ? describedBy : undefined"
        >
            <slot />
        </CheckboxLabel>

        <div v-if="boxSide === 'right'" class="flex h-5 items-center">
            <BaseCheckbox
                :id="field"
                :name="field"
                :checked="modelValue"
                :disabled="disabled"
                :aria-describedBy="showDescription ? describedBy : undefined"
                @change="handleChange"
            />
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import BaseCheckbox from "@/Shared/Forms/Components/BaseCheckbox.vue";
import CheckboxLabel from "@/Shared/Forms/Components/CheckboxLabel.vue";

const props = defineProps({
    boxSide: {
        type: String,
        validator: (value) => ["left", "right"].includes(value),
        default: "left",
    },
    field: {
        type: String,
        required: true,
    },
    modelValue: Boolean,
    disabled: {
        type: Boolean,
        default: false,
    },
    description: String,
});

const emit = defineEmits(["update:modelValue"]);

const showDescription = computed(() => props.description?.trim() !== "");
const describedBy = computed(() => `${props.field}-description`);

const handleChange = (event) => {
    emit("update:modelValue", event.target.checked);
};
</script>
